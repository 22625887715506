import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { TypeIllustratedText, isTypeIllustratedText } from '../../../@types/generated';
import { breakpoints, colors, spacing } from '../../utils/styleguide';
import GhostButton from '../component-library/buttons/GhostButton';
import PrimaryButton from '../component-library/buttons/PrimaryButton';
import LayoutHorizontalListSection from '../component-library/layout/HorizontalListSection/HorizontalListLayout';
import SimpleModal from '../component-library/modals/SimpleModal';
import SectionBackground from '../component-library/SectionBackground';
import Typography from '../component-library/text/Typography';
import ContentfulImage, { ImageStyleProps } from './Image';
import { PageSectionComponent } from './pageSections';
import RichText from './RichText/RichText';
import buttonToProps from './util/buttonToCompatibleProps';
import orientedMediaToCompatibleProps from './util/orientedMediaToCompatibleProps';
import sectionToCompatibleProps from './util/sectionToCompatibleProps';

type ModalContentProps = {
  readMore: TypeIllustratedText['fields'];
  setReadMore: Dispatch<SetStateAction<TypeIllustratedText['fields'] | undefined>>;
};

const ReadMoreModalContentContainer = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`;

export const ReadMoreModalContent = ({ readMore, setReadMore }: ModalContentProps) => {
  const { image } = orientedMediaToCompatibleProps({
    orientedMedia: readMore.media,
    image: readMore.image,
  });
  return (
    <ReadMoreModalContentContainer>
      {image && (
        <ContentfulImage
          wrapperCss={css`
            max-width: 40px;
            max-height: 40px;
          `}
          css={css`
            height: 100%;
            width: 100%;
            object-fit: contain;
          `}
          asset={image}
        />
      )}
      <Typography
        variant="bodySmall"
        css={css`
          margin-top: ${spacing[3]}px;
        `}
      >
        {readMore.readMoreText}
      </Typography>
      <PrimaryButton
        css={css`
          width: 100%;
          margin: ${spacing[6]}px 0px 0px;
        `}
        size="small"
        label={readMore.readMoreCloseButton?.fields.label || 'Got it'}
        onClick={() => setReadMore(undefined)}
      />
    </ReadMoreModalContentContainer>
  );
};

const titleImageStyle = css`
  border-radius: 4px;
`;

const logoStyle = css`
  height: 48px;
  width: auto;
  max-width: 130px;
  object-fit: contain;
  object-position: left;
  ${breakpoints.desktop} {
    height: 40px;
  }
`;

const HorizontalListSection: PageSectionComponent<null> = ({ section }) => {
  const [readMore, setReadMore] = useState<TypeIllustratedText['fields']>();
  const { title, backgroundColor, id: sectionId } = sectionToCompatibleProps({ section });
  const titleFunc = (props: { color: string }) => (
    <RichText document={title} textColor={props.color} centered />
  );
  const { references } = section.fields;

  const sections = (references || []).filter(
    (ref): ref is TypeIllustratedText => !!ref && isTypeIllustratedText(ref),
  );

  const Title = title && titleFunc;
  const modalContent = useMemo(() => {
    if (readMore) return <ReadMoreModalContent readMore={readMore} setReadMore={setReadMore} />;
    return undefined;
  }, [readMore, setReadMore]);

  return (
    <SectionBackground id={sectionId} backgroundColor={backgroundColor}>
      <SimpleModal
        open={!!modalContent}
        onClose={() => setReadMore(undefined)}
        body={modalContent}
        hideToolbar
        centerContent
        padding={spacing[4]}
      />
      <LayoutHorizontalListSection
        Title={Title}
        sections={sections?.map(({ fields }) => {
          const { image, maxWidth } = orientedMediaToCompatibleProps({
            orientedMedia: fields.media,
            image: fields.image,
          });
          const { label, href } =
            buttonToProps({
              button: fields.button,
            }) || {};

          const handleLinkClick = (fields: TypeIllustratedText['fields']) => {
            setReadMore(fields);
          };
          return {
            title: (fields.title && <RichText document={fields.title} />) || '',
            content:
              (fields.description && (
                <RichText textColor={colors.darkGrey} document={fields.description} />
              )) ||
              '',
            Image:
              image &&
              ((props: ImageStyleProps) => (
                <ContentfulImage
                  asset={image}
                  {...props}
                  css={css`
                    ${titleImageStyle};
                    ${props.css}
                  `}
                />
              )),
            Logos: fields?.logos?.map((image) => (
              <ContentfulImage key={image?.sys.id} asset={image} css={logoStyle} />
            )),
            Button:
              href || fields.readMoreText ? (
                <GhostButton
                  href={!fields.readMoreText ? href : undefined}
                  onClick={fields.readMoreText ? () => handleLinkClick(fields) : undefined}
                  label={label || 'Learn more'}
                  color={colors.blueDark}
                  hideUnderline
                  trailingIconName="arrow-long-right"
                />
              ) : undefined,
            isIcon: fields.isIcon,
            maxMediaWidth: maxWidth,
          };
        })}
      />
    </SectionBackground>
  );
};

export default HorizontalListSection;
